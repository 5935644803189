<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看预约{{ database.deliverModeName }}</div>
      <div class="erp-form-bar">
        <div
          class="erp-primary__btn"
          @click="edit"
          v-if="hasPermission('erp:smDeliver:operateDeliver')"
        >
          编辑
        </div>
        <div
          class="erp-default__btn"
          v-if="hasPermission('erp:smDeliver:operateDeliver')"
          style="
            border: 1px solid rgba(40, 120, 255, 0.3);
            background: rgba(233, 241, 255, 1);
            color: rgba(40, 120, 255, 1);
          "
          @click="cancel"
        >
          取消预约
        </div>
        <div
          class="erp-primary__btn erp_FFF"
          @click="exportdata"
          v-if="database.deliverModeName !== '直送'"
        >
          导出
        </div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp op-16">
      <el-row
        v-if="editData.category === 1"
        :gutter="30"
        type="flex"
        class="flex-warp"
      >
        <el-col :span="12">
          <erp-view
            :label="
              database.deliverModeName === '直送' ? '送货日期' : '入库日期'
            "
            :value="database.deliverDate"
          ></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view
            label="送货方式"
            :value="database.deliverModeName"
          ></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="报关单号" :value="database.declarationNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="报检号" :value="database.inspectionNo"></erp-view>
        </el-col>
        <el-col
          :span="12"
          v-if="editData.category === 1 && database.deliverModeName !== '直送'"
        >
          <erp-view label="冷库" :value="database.storageName"></erp-view>
        </el-col>
        <el-col
          :span="12"
          v-if="editData.category === 1 && database.deliverModeName !== '直送'"
        >
          <erp-view
            label="检验方式"
            :value="database.inspectModeName"
          ></erp-view>
        </el-col>
        <el-col
          :span="12"
          v-if="editData.category === 1 && database.deliverModeName === '直送'"
        >
          <erp-view
            label="送货地址"
            :value="database.deliverAddress"
          ></erp-view>
        </el-col>
        <el-col
          :span="12"
          v-if="editData.category === 1 && database.deliverModeName === '直送'"
        >
          <erp-view label="结算日期" :value="database.settleDate"></erp-view>
        </el-col>
        <el-col
          :span="12"
          v-if="editData.category === 1 && database.deliverModeName !== '直送'"
        >
          <erp-view label="货主" :value="database.ownerName"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="送货问题" :value="database.deliverIssue"></erp-view>
        </el-col>
      </el-row>
      <!--现货查看-->
      <el-row
        v-if="editData.category !== 1"
        :gutter="30"
        type="flex"
        class="flex-warp"
      >
        <el-col :span="12">
          <erp-view label="送货日期" :value="database.deliverDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view
            label="送货方式"
            :value="database.deliverModeName"
          ></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="冷库" :value="database.storageName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="货主" :value="database.ownerName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view
            label="结算起始日"
            :value="database.xhfwSettleStartDate"
          ></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="送货问题" :value="database.deliverIssue"></erp-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { exportFile } from '@/utils/publicMethods';
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      database: {},
      DialogData: {
        dialogVisible: false,
        title: '确定要取消预约吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      },
    };
  },
  created() {
    this.getinitdata();
  },
  methods: {
    /**
     * 提交事件
     */
    edit() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/checkDeliver/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            // this.database = res.result;
            if (res.result) {
              this.$emit('edit');
            } else {
              this.errorTip('结算单已关联收款，无法编辑');
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },

    exportdata() {
      exportFile({
        type: 'POST',
        data: {
          id: this.database.id,
        },
        url: '/malicrm/sm/deliver/exportInStore',
      });
    },
    cancel() {
      this.$erpConfirm('确定要取消预约吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/sm/deliver/cancelDeliver',
            data: {
              id: this.database.id,
              orderId: this.editData.orderId,
              version: this.database.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('已取消预约');
                // this.database = res.result;
                this.$emit('success', true);
              } else {
                this.DialogData.dialogVisible = false;
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/deliverDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.database = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.viewform__one_body {
  display: flex;
  // flex: 1;
  width: 50%;
}
.viewform__main {
  display: flex;
  flex-direction: row;
}
.padding__top {
  padding-top: 20px;
}
.viewfrom_body_title {
  display: flex;
  width: 90px;
  color: rgba(115, 124, 140, 1);
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body_value {
  display: flex;
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 15px;
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body {
  width: 100%;
  min-height: 42px;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
}
</style>
