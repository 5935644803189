import { httpPost } from '@/api';
import { ErpTableOptions } from '@/types/type';
import { erpConfirm, errorMessage, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '供应商',
      prop: 'supplierName',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryName',

      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '品名',

      prop: 'nameCn',

      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '件数',
      prop: 'packageCount',
      minWidth: 100,
      value: [],
      type: 'number',
      filterProp: ['packageCountMin', 'packageCountMax'],
    },
    {
      label: '实际净重(kg)',
      prop: 'actualWeight',
      propDesc: 'actualWeightString',
      minWidth: 154,
      value: [],
      type: 'number',
      filterProp: ['actualWeightMin', 'actualWeightMax'],
    },
    {
      label: '实际货值',
      prop: 'actualAmountSum',
      propDesc: 'actualAmountSumDesc',
      minWidth: 154,
      value: [],
      type: 'number',
      filterProp: ['actualAmountSumMinVal', 'actualAmountSumMaxVal'],
    },
    {
      label: '口岸',
      prop: 'portName',
      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      value: '',
      type: 'input',
      minWidth: 160,
    },
    {
      label: '客户',
      prop: 'customerName',
      value: '',
      type: 'input',
      minWidth: 183,
    },
    {
      label: '柜号',
      prop: 'containerNo',
      value: '',
      type: 'input',
      minWidth: 128,
    },
    {
      label: '提单号',
      minWidth: 138,
      prop: 'ladingBillNo',
      value: '',
      type: 'input',
    },
    {
      label: '生产日期',
      prop: 'produceDate',
      value: '',
      type: 'input',
      className: '',
      minWidth: 128,
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',
      minWidth: 112,
      value: [],
      type: 'time',
      filterProp: ['shippingEndDateBegin', 'shippingEndDateEnd'],
    },
    {
      label: '尾款日期',
      prop: 'arPayDate',
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['arPayDateBegin', 'arPayDateEnd'],
    },
    {
      label: '交报关行时间',
      prop: 'performDate',
      value: [],
      type: 'time',
      minWidth: 155,
      filterProp: ['performDateBegin', 'performDateEnd'],
    },
    {
      label: '报关描述',
      prop: 'description',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '送货状态',
      prop: 'deliverStatus',
      propDesc: 'deliverStatusName',
      minWidth: 128,
      value: [1, 2, 3, 6],
      type: 'multiSelect',

      options: 'deliver_status',
    },
    {
      label: '送货日期',
      prop: 'deliverDate',
      minWidth: 128,
      value: [],
      type: 'time',
      options: 'order_status',
      filterProp: ['deliverDateBegin', 'deliverDateEnd'],
    },
    {
      label: '送货地址',
      prop: 'deliverAddress',
      minWidth: 155,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '送货问题',
      prop: 'deliverIssue',

      minWidth: 127,
      value: '',
      type: 'input',
    },
    {
      label: '还箱日期',
      prop: 'boxReturnDate',
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['boxReturnDateBegin', 'boxReturnDateEnd'],
    },
    {
      label: '免箱期',
      prop: 'boxFreePeriod',
      minWidth: 140,
      value: '',
      type: 'input',
      isSort: false,
      isFilter: false,
    },
    {
      label: '还箱地址',
      prop: 'boxReturnAddress',
      minWidth: 128,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
    },
    {
      label: '还箱描述',
      prop: 'boxDescription',
      minWidth: 155,
      value: '',
      type: 'input',
    },
    {
      label: '代理',
      prop: 'agencyName',
      minWidth: 101,
      value: '',
      type: 'input',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 210,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain v-if="hasPermission('erp:smDeliver:operateDeliver') && row.deliverStatus == 1"  @click='makedelivery(row)'>预约送货</el-button>
        <el-button type="warning" plain v-if="hasPermission('erp:smDeliver:sureDeliver') && row.deliverStatus == 2"  @click='confirmDelivery(row)'>确认直送</el-button>
        <el-button type="warning" plain v-if="hasPermission('erp:smDeliver:sureDeliver') && row.deliverStatus == 3"  @click='confirmaddContract(row)'>确认入库</el-button>
        <el-button type="warning" plain v-if="hasPermission('erp:smDeliver:auditInStore') && row.deliverStatus == 6"  @click='auditwarehousing(row)'>审核入库</el-button>
        <el-button type="warning" plain v-if="row.deliverStatus == 5"  @click='updataFile(row)'>更新附件</el-button>
        <el-button type="primary" plain v-if="row.deliverStatus == 2 || row.deliverStatus == 3"  @click='cancelDelivery(row)'>取消预约</el-button>
        <el-button type="warning" plain v-if="row.isCanRollback && row.deliverStatus == 5"  @click='undo(row)'>撤销</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const makedelivery = (row) => {
            Instance.setupState.makedelivery(row);
          };
          const confirmDelivery = (row) => {
            Instance.setupState.confirmDelivery(row);
          };
          const auditwarehousing = (row) => {
            Instance.setupState.auditwarehousing(row);
          };
          const confirmaddContract = (row) => {
            Instance.setupState.confirmaddContract(row);
          };
          const updataFile = (row) => {
            Instance.setupState.updataFile(row);
          };
          const cancelDelivery = (row) => {
            console.log(row.deliverStatus);
            erpConfirm('确定取消预约吗？').then(() => {
              httpPost('/malicrm/sm/deliver/cancelDeliver', { id: row.id, orderId: row.orderId, version: row.version }).then((res) => {
                if (res.code == 200) {
                  successMessage('取消成功！');
                  tableRef.value.refreshTable(false);
                } else {
                  errorMessage(res.message);
                }
              });
            });
          };
          const undo = (row) => {
            console.log(row);
            erpConfirm('确定执行撤销操作吗？').then(() => {
              httpPost('/malicrm/sm/deliver/rollbackInStore', { id: row.id, version: row.version }).then((res) => {
                if (res.code == 200) {
                  successMessage('撤销成功！');
                  tableRef.value.refreshTable(false);
                } else {
                  errorMessage(res.message);
                }
              });
            });
          };
          return {
            makedelivery,
            cancelDelivery,
            confirmDelivery,
            auditwarehousing,
            confirmaddContract,
            updataFile,
            undo,
          };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
