import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_makedelform = _resolveComponent("makedelform")!
  const _component_confirmform = _resolveComponent("confirmform")!
  const _component_confirmaddform = _resolveComponent("confirmaddform")!
  const _component_makedetail = _resolveComponent("makedetail")!
  const _component_confirmdeldetail = _resolveComponent("confirmdeldetail")!
  const _component_confirmadddetail = _resolveComponent("confirmadddetail")!
  const _component_auditwarehousing = _resolveComponent("auditwarehousing")!
  const _component_updateFile = _resolveComponent("updateFile")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/sm/deliver/deliverList",
      rowId: "id",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "deliveryList",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:smDeliver:exportDeliver'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "erp-default__btn ml-8",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportFileFn && _ctx.exportFileFn(...args)))
            }, "导出"))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible,
      onClose: _ctx.cancel,
      erp: "",
      class: "fix-top-header"
    }, {
      default: _withCtx(() => [
        (_ctx.type === 1)
          ? (_openBlock(), _createBlock(_component_makedelform, {
              key: 0,
              formType: _ctx.formType,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["formType", "editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.type === 2)
          ? (_openBlock(), _createBlock(_component_confirmform, {
              key: 1,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback
            }, null, 8, ["formType", "onCancel", "editData", "onSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.type === 3)
          ? (_openBlock(), _createBlock(_component_confirmaddform, {
              key: 2,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback
            }, null, 8, ["formType", "onCancel", "editData", "onSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.type === 4)
          ? (_openBlock(), _createBlock(_component_makedetail, {
              key: 3,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onEdit: _ctx.editCallback
            }, null, 8, ["formType", "onCancel", "editData", "onSuccess", "onEdit"]))
          : _createCommentVNode("", true),
        (_ctx.type === 5)
          ? (_openBlock(), _createBlock(_component_confirmdeldetail, {
              key: 4,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback
            }, null, 8, ["formType", "onCancel", "editData", "onSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.type === 6)
          ? (_openBlock(), _createBlock(_component_confirmadddetail, {
              key: 5,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback
            }, null, 8, ["formType", "onCancel", "editData", "onSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.type === 7 || _ctx.type === 8)
          ? (_openBlock(), _createBlock(_component_auditwarehousing, {
              key: 6,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback
            }, null, 8, ["formType", "onCancel", "editData", "onSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.type === 9)
          ? (_openBlock(), _createBlock(_component_updateFile, {
              key: 7,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}