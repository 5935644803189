<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看入库</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <!--期货查看入库-->
      <el-row v-if="editData.category === 1" :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="入库日期" :value="formData.deliverDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="送货方式" :value="formData.deliverModeName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="报关单号" :value="formData.declarationNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="报检号" :value="formData.inspectionNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="冷库" :value="formData.storageName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="检验方式" :value="formData.inspectModeName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="货主" :value="formData.ownerName"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="送货问题" :value="formData.deliverIssue"></erp-view>
        </el-col>
      </el-row>
      <!--现货查看入库-->
      <el-row v-if="editData.category !== 1" :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="送货日期" :value="formData.deliverDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="送货方式" :value="formData.deliverModeName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="冷库" :value="formData.storageName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="货主" :value="formData.ownerName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="结算起始日" :value="formData.xhfwSettleStartDate"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="送货问题" :value="formData.deliverIssue"></erp-view>
        </el-col>
      </el-row>
      <div class="op-16">
        <div class="confirmadd__title" style="margin-top: 20px">产品信息</div>
      </div>
      <div class="claim_company">
        <!--期货产品信息-->
        <el-table
          v-if="editData.category === 1"
          :show-summary="showSummary"
          :summary-method="qhSummaryFn"
          :data="productList"
          border
          class="egrid product_table_total erp-table__list erp-view__table"
        >
          <el-table-column label="厂号" prop="plantNo" align="center" class-name="no-pd" show-overflow-tooltip width="90">
            <template #default="scope">
              <div>{{ filterName(scope.row.plantNo) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="中文品名" prop="nameCn" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.nameCn) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="英文品名" prop="nameEn" class-name="no-pd" align="center" show-overflow-tooltip width="338">
            <template #default="scope">
              <div>{{ filterName(scope.row.nameEn) }}</div>
            </template>
          </el-table-column>

          <el-table-column label="实际净重(kg)" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>{{ filterName(scope.row.actualWeightDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="件数" class-name="no-pd" align="center" show-overflow-tooltip width="68">
            <template #default="scope">
              <div>{{ filterName(scope.row.packageCountDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="入库件数" prop="instoreCount" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.instoreCountDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="溢短" prop="overShort" align="center" class-name="no-pd" show-overflow-tooltip width="61">
            <template #default="scope">
              <div>{{ filterName(scope.row.overShortDisplay) }}</div>
            </template>
          </el-table-column>
        </el-table>
        <!--现货产品信息-->
        <el-table
          v-if="editData.category !== 1"
          :show-summary="showSummary"
          :summary-method="xhSummaryFn"
          :data="productList"
          border
          class="egrid product_table_total erp-table__list erp-view__table"
        >
          <el-table-column label="货物信息" prop="joinName" align="center" class-name="no-pd" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.joinName) }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="formData.isAbnormal" label="单价" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>{{ filterName(scope.row.unitPriceDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="重量(kg)" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>{{ filterName(scope.row.contractWeightDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="件数" class-name="no-pd" align="center" show-overflow-tooltip width="68">
            <template #default="scope">
              <div>{{ filterName(scope.row.packageCountDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="入库件数" prop="instoreCount" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.instoreCountDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="formData.isAbnormal" label="入库重量" prop="instoreWeight" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.instoreWeightDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="formData.isAbnormal" label="实际货值" prop="actualValue" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.actualValueDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="formData.isAbnormal" label="合同货值" prop="contractValue" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.contractValueDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!formData.isAbnormal" label="溢短" prop="overShort" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.overShortDisplay) }}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="formData.isAbnormal" label="货值差" prop="valueDiff" align="center" class-name="no-pd" show-overflow-tooltip width="110">
            <template #default="scope">
              <div>{{ filterName(scope.row.valueDiffDisplay) }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="font-size: 12px; color: #f04737; padding: 7px 16px 0 16px" v-if="formData.isAbnormal">
        注：如果入库重量和合同重量相差较大，需要签补充协议时，才需填写本表。
      </div>
      <div class="confirmadd__title op-16" style="margin: 20px 0 0 16px">入库文件</div>
      <div class="op-16">
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12">
            <erp-view label="入库单号" :value="formData.instoreReceiptNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="入库单文件">
              <div style="flex: 1">
                <div class="card-preview" v-for="(item, index) in crmAttachmentVo" :key="index">
                  <span>{{ item.name }}</span>
                  <img src="../../../../assets/images/preview__zoom.png" alt @click="$showFiles(item.name, item.url)" />
                  <img src="../../../../assets/images/preview_download.png" alt @click="$showFiles(item.name, item.url, false)" />
                </div>
              </div>
            </erp-view>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      formData: {
        declarationNo: 'AAAAA',
        deliverDate: '2019-10-14',
        deliverIssue: '打飞机阿里富家大室了飞机第三方',
        deliverMode: '1',
        deliverModeName: '入库',
        id: 0,
        inspectMode: '2',
        inspectModeName: '动检',
        inspectionNo: '111',
        instoreReceiptNo: '3432423',
        isAbnormal: true,
        orderId: 0,
        ownerName: '洪迈',
        ownerType: 'string',
        ownerTypeName: 'string',
        attachmentVos: [],
        stockProductVos: [
          {
            actualValue: 1343,
            actualValueDisplay: '1,343',
            actualWeight: 1350,
            actualWeightDisplay: '2,350',
            category: '牛肉',
            contractValue: 50,
            contractValueDisplay: '50',
            contractWeight: 1110,
            contractWeightDisplay: '1,110',
            country: '澳大利亚',
            instoreCount: 2000,
            instoreCountDisplay: '2,000',
            instoreWeight: 56000,
            instoreWeightDisplay: '56,000',
            joinName: '牛_新西兰_ME47_去盖臀肉_FROZEN BONELESS BEEF THIN SKIRT',
            nameCn: '去盖臀肉',
            nameEn: 'FROZEN BONELESS BEEF THIN SKIRT',
            overShort: -2,
            packageCount: 3000,
            packageCountDisplay: '3,000',
            plantNo: 'ME47',
            unitPrice: 80,
            unitPriceDisplay: '80',
            unitWeight: 0,
            valueDiff: 6300,
            valueDiffDisplay: '6,300',
          },
          {
            actualValue: 1343,
            actualValueDisplay: '1,343',
            actualWeight: 1350,
            actualWeightDisplay: '2,350',
            category: '牛肉',
            contractValue: 50,
            contractValueDisplay: '50',
            contractWeight: 1110,
            contractWeightDisplay: '1,110',
            country: '新西兰',
            instoreCount: 2000,
            instoreCountDisplay: '2,000',
            instoreWeight: 5600,
            instoreWeightDisplay: '5,600',
            joinName: '牛_新西兰_ME47_冷冻牛板腱_FROZEN BONELESS BEEF THIN SKIRT',
            nameCn: '冷冻牛板腱',
            nameEn: 'FROZEN BONELESS BEEF THIN SKIRT',
            overShort: -2,
            packageCount: 3100,
            packageCountDisplay: '3,100',
            plantNo: 'ME47',
            unitPrice: 980,
            unitPriceDisplay: '980',
            unitWeight: 0,
            valueDiff: 6320,
            valueDiffDisplay: '6,320',
          },
        ],
        storageName: '上海市浦东新区欣阳路120号',
        totalActual: 0,
        totalActualValue: 0,
        totalContractValue: 0,
        totalCount: 0,
        totalInstoreCount: 0,
        totalInstoreWeight: 0,
        totalValueDiff: 0,
        totalWeight: 0,
        version: 1,
        xhfwSettleStartDate: '2019-10-14',
      },
      productList: [],
      crmAttachmentVo: [],
      showSummary: false,
    };
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
  created() {
    this.initDetailData();
  },
  methods: {
    /**
     * 初始化确认入库信息
     */
    initDetailData() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/deliverDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.productList = this.formData.sumVo.productVos;
            this.crmAttachmentVo = this.formData.attachmentVos;
            if (this.productList.length > 1) {
              this.showSummary = true;
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     * 现货合计行
     * @returns {[string,string,string,string,string,string,string,string]}
     */
    xhSummaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', '', ''];
      const data = this.productList;
      // 重量
      let contractWeightTotal = data.reduce((total, item) => {
        const contractWeightTotal = Number(item.contractWeight);
        return total + contractWeightTotal;
      }, 0);
      contractWeightTotal = this._formatFloat(contractWeightTotal, 3);
      // 件数
      let packageCountTotal = data.reduce((total, item) => {
        const packageCountTotal = Number(item.packageCount);
        return total + packageCountTotal;
      }, 0);
      if (isNaN(packageCountTotal)) {
        packageCountTotal = 0;
      }
      // 入库件数
      let instoreCountTotal = data.reduce((total, item) => {
        const instoreCountTotal = Number(item.instoreCount);
        return total + instoreCountTotal;
      }, 0);
      if (isNaN(instoreCountTotal)) {
        instoreCountTotal = 0;
      }
      // false正常入库
      if (!this.formData.isAbnormal) {
        // 溢短
        let overShortTotal = data.reduce((total, item) => {
          const overShortTotal = Number(item.overShort);
          return total + overShortTotal;
        }, 0);
        if (isNaN(overShortTotal)) {
          overShortTotal = 0;
        }
        summaryColumns[1] = this._thousandBitSeparator(contractWeightTotal); // 重量
        summaryColumns[2] = this._thousandBitSeparator(packageCountTotal); // 件数
        summaryColumns[3] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
        summaryColumns[4] = this._thousandBitSeparator(overShortTotal); // 溢短
      } else {
        // 异常入库
        // 入库重量
        let instoreWeightTotal = data.reduce((total, item) => {
          const instoreWeightTotal = Number(item.instoreWeight);
          return total + instoreWeightTotal;
        }, 0);
        instoreWeightTotal = this._formatFloat(instoreWeightTotal, 3);
        // 实际货值
        let totalAmountTotal = data.reduce((total, item) => {
          const totalAmountTotal = Number(item.actualValue);
          return total + totalAmountTotal;
        }, 0);
        totalAmountTotal = this._formatFloat(totalAmountTotal, 2);
        // 合同货值
        let contractValueTotal = data.reduce((total, item) => {
          const contractValueTotal = Number(item.contractValue);
          return total + contractValueTotal;
        }, 0);
        contractValueTotal = this._formatFloat(contractValueTotal, 2);
        // 货值差
        let valueDiffTotal = data.reduce((total, item) => {
          const valueDiffTotal = Number(item.valueDiff);
          return total + valueDiffTotal;
        }, 0);
        valueDiffTotal = this._formatFloat(valueDiffTotal, 2);
        summaryColumns[2] = this._thousandBitSeparator(contractWeightTotal); // 重量
        summaryColumns[3] = this._thousandBitSeparator(packageCountTotal); // 件数
        summaryColumns[4] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
        summaryColumns[5] = this._thousandBitSeparator(instoreWeightTotal); // 入库重量
        summaryColumns[6] = this._thousandBitSeparator(totalAmountTotal); // 实际货值
        summaryColumns[7] = this._thousandBitSeparator(contractValueTotal); // 实际货值
        summaryColumns[8] = this._thousandBitSeparator(valueDiffTotal); // 合同货值
      }
      return summaryColumns;
    },
    /**
     * 期货合计列
     * @returns {[string,string,string,string,string,string,string,string]}
     */
    qhSummaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', ''];
      const data = this.productList;
      // 实际净重
      let actualWeightTotal = data.reduce((total, item) => {
        const actualWeightTotal = Number(item.actualWeight);
        return total + actualWeightTotal;
      }, 0);
      actualWeightTotal = this._formatFloat(actualWeightTotal, 3);
      // 件数
      let packageCountTotal = data.reduce((total, item) => {
        const packageCountTotal = Number(item.packageCount);
        return total + packageCountTotal;
      }, 0);
      if (isNaN(packageCountTotal)) {
        packageCountTotal = 0;
      }
      // 入库件数
      let instoreCountTotal = data.reduce((total, item) => {
        const instoreCountTotal = Number(item.instoreCount);
        return total + instoreCountTotal;
      }, 0);
      if (isNaN(instoreCountTotal)) {
        instoreCountTotal = 0;
      }
      // 溢短
      let overShortTotal = data.reduce((total, item) => {
        const overShortTotal = Number(item.overShort);
        return total + overShortTotal;
      }, 0);
      if (isNaN(overShortTotal)) {
        overShortTotal = 0;
      }
      summaryColumns[3] = this._thousandBitSeparator(actualWeightTotal); // 实际净重
      summaryColumns[4] = this._thousandBitSeparator(packageCountTotal); // 件数
      summaryColumns[5] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
      summaryColumns[6] = this._thousandBitSeparator(overShortTotal); // 溢短
      return summaryColumns;
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-preview {
  margin-bottom: 16px;
}
.card-preview:last-child {
  margin-bottom: 0;
}
.viewform__one_body {
  display: flex;
  flex: 1;
  width: 50%;
}

.viewform__main {
  display: flex;
  flex-direction: row;
}

.padding__top {
  padding-top: 20px;
}

.viewfrom_body_title {
  display: flex;
  width: 90px;
  color: rgba(115, 124, 140, 1);
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}

.viewfrom_body_value {
  display: flex;
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 15px;
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}

.viewfrom_body {
  width: 100%;
  min-height: 42px;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
}
</style>
