<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看直送</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <el-row :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="送货日期" :value="database.deliverDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="送货方式" :value="database.deliverModeName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="报关单号" :value="database.declarationNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="报检号" :value="database.inspectionNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="送货地址" :value="database.deliverAddress"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="结算日期" :value="database.settleDate"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="送货问题" :value="database.deliverIssue"></erp-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      database: {},
    };
  },
  created() {
    this.getinitdata();
  },
  methods: {
    /**
     * 提交事件
     */
    submit() {
      this.$emit('success');
    },

    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/deliverDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.database = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.viewform__one_body {
  display: flex;
  // flex: 1;
  width: 50%;
}
.viewform__main {
  display: flex;
  flex-direction: row;
}
.padding__top {
  padding-top: 20px;
}
.viewfrom_body_title {
  display: flex;
  width: 90px;
  color: rgba(115, 124, 140, 1);
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body_value {
  display: flex;
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 15px;
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body {
  width: 100%;
  min-height: 42px;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
}
</style>
