<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">预约送货</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form" style="width: 100%">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item
                :label="formData.deliverMode === 1 ? '送货日期' : '入库日期'"
                prop="deliverDate"
                :rules="[
                  formData.deliverMode === 1
                    ? {
                        required: true,
                        message: '请选择送货日期',
                        trigger: 'blur',
                      }
                    : {
                        required: true,
                        message: '请选择入库日期',
                        trigger: 'blur',
                      },
                ]"
              >
                <el-date-picker
                  v-model="formData.deliverDate"
                  type="date"
                  :placeholder="formData.deliverMode === 1 ? '请选择送货日期' : '请选择入库日期'"
                  prefix-icon=" "
                  style="width: 100%"
                  @change="formatTime(formData, 'deliverDate', $event)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="送货方式" prop="deliverMode">
                <el-select
                  v-model="formData.deliverMode"
                  class="mali-full__input"
                  :disabled="formType === 2 || (formType === 1 && editData.category !== 1)"
                  placeholder="请选择送货方式"
                  @change="clearForm"
                >
                  <el-option v-for="item in writtenOffList.deliver_mode" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="editData.category === 1">
              <el-form-item label="报关单号" prop="declarationNo">
                <el-input
                  :maxlength="40"
                  v-model="formData.declarationNo"
                  clearable
                  @input="changeinput"
                  placeholder="请输入报关单号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="editData.category === 1">
              <el-form-item label="报检号" prop="inspectionNo">
                <el-input
                  :maxlength="40"
                  v-model="formData.inspectionNo"
                  clearable
                  @input="inspecinput"
                  placeholder="请输入报检号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formData.deliverMode === 2">
              <el-form-item label="冷库" prop="storageId">
                <el-select
                  v-model="formData.storageId"
                  class="mali-full__input"
                  @change="selectstorage"
                  placeholder="请选择冷库"
                  :remote-method="getcoldlist"
                  filterable
                  remote
                  clearable
                >
                  <el-option v-for="item in coldlist" :key="item.value" :label="item.shortName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formData.deliverMode === 2 && editData.category === 1">
              <el-form-item label="检验方式" prop="inspectMode">
                <el-select v-model="formData.inspectMode" class="mali-full__input" placeholder="请选择检验方式">
                  <el-option v-for="item in writtenOffList.inspect_mode" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formData.deliverMode === 2">
              <el-col :span="8" style="padding-left: 0px">
                <el-form-item label="货主" prop="ownerType">
                  <el-select
                    v-model="formData.ownerType"
                    class="mali-full__input"
                    style="width: 148px !important"
                    @change="ownerTypechange"
                    placeholder="请选择货主类型"
                  >
                    <el-option v-for="item in stockownertype" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" style="padding-top: 25px; padding-left: 15px">
                <el-form-item prop="ownerName">
                  <div class="make_ownerName">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="formData.ownerName"
                      placement="top-start"
                      :disabled="!formData.ownerName || (formData.ownerName && formData.ownerName.length < 11)"
                    >
                      <span>{{ formData.ownerName }}</span>
                    </el-tooltip>
                  </div>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="12" v-if="formData.deliverMode === 1 && editData.category === 1">
              <el-form-item label="送货地址" prop="deliverAddress">
                <el-input :maxlength="100" v-model="formData.deliverAddress" clearable placeholder="请输入送货地址" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formData.deliverMode === 1 && editData.category === 1">
              <el-form-item label="结算日期" prop="settleDate">
                <div style="position: absolute; top: -10px; left: 310px; color: #8a93a4; font-size: 13px">
                  <img src="../../../../assets/images/icon_warn.png" alt="" style="position: relative; top: 2px" />
                  结算时计算利息的结束日
                </div>
                <el-date-picker
                  v-model="formData.settleDate"
                  type="date"
                  placeholder="请选择结算日期"
                  prefix-icon=" "
                  style="width: 100%"
                  @change="formatTime(formData, 'settleDate', $event)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="editData.category !== 1">
              <el-form-item label="结算起始日" prop="xhfwSettleStartDate">
                <div style="position: absolute; top: -10px; left: 296px; color: #8a93a4; font-size: 13px">
                  <img src="../../../../assets/images/icon_warn.png" alt="" style="position: relative; top: 2px" />
                  资方支付上游全部货款日期
                </div>
                <el-date-picker
                  v-model="formData.xhfwSettleStartDate"
                  type="date"
                  placeholder="请选择结算起始日"
                  prefix-icon=" "
                  style="width: 100%"
                  @change="formatTime(formData, 'xhfwSettleStartDate', $event)"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="送货问题" prop="deliverIssue">
                <el-input
                  v-model="formData.deliverIssue"
                  placeholder="请输入送货问题"
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { store } from '@/store';
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      saveBtnTimer: null,
      writtenOffList: {
        inspect_mode: store.state.dict.options.inspect_mode,

        deliver_mode: store.state.dict.options.deliver_mode,

        stock_owner_type: store.state.dict.options.stock_owner_type,
      },
      stockownertype: [
        {
          label: '资金方',
          value: 1,
        },
        {
          label: '抬头',
          value: 2,
        },
        {
          label: '客户',
          value: 3,
        },
      ],
      coldlist: [],
      formData: {
        declarationNo: null,
        deliverAddress: null,
        deliverDate: null,
        deliverIssue: null,
        deliverMode: 2,
        id: null,
        inspectMode: 1,
        inspectionNo: null,
        isNeedJr: false,
        orderId: null,
        ownerName: null,
        ownerType: null,
        settleDate: null,
        storageAddressCity: null,
        storageAddressCityId: null,
        storageAddressDetail: null,
        storageAddressDistrict: null,
        storageAddressDistrictId: null,
        storageAddressProvince: null,
        storageAddressProvinceId: null,
        storageId: null,
        storageName: null,
        xhfwSettleStartDate: null,
        version: null,
      },
      rules: {
        // 检验规则
        deliverDate: [
          {
            required: true,
            message: '请选择送货日期',
            trigger: 'blur',
          },
        ],
        settleDate: [
          {
            required: true,
            message: '请选择结算日期',
            trigger: 'blur',
          },
        ],
        xhfwSettleStartDate: [
          {
            required: true,
            message: '请选择结算起始日',
            trigger: 'blur',
          },
        ],
        deliverMode: [
          {
            required: true,
            message: '请选择送货方式',
            trigger: 'blur',
          },
        ],
        ownerType: [
          {
            required: true,
            message: '请选择货主类型',
            trigger: 'change',
          },
        ],
        deliverAddress: [
          {
            required: true,
            message: '请输入送货地址',
            trigger: 'blur',
          },
        ],
        inspectMode: [
          {
            required: true,
            message: '请选择检验方式',
            trigger: 'blur',
          },
        ],
        storageId: [
          {
            required: true,
            message: '请选择冷库',
            trigger: 'change',
          },
        ],
        isWrittenOff: [
          {
            required: true,
            message: '请选择核销状态',
            trigger: 'change',
          },
        ],
      },
    };
  },
  created() {
    if (this.formType !== 1) {
      this.getinitdata();
    }
    if (this.editData.category !== 1) {
      // bug:7901 现货的话默认添加冷库
      this.getDefaultStorageId(this.editData.orderId);
    } else {
      this.getcoldlist();
    }

    this.formData.orderId = this.editData.orderId;
    this.formData.isNeedJr = this.editData.isNeedJr;
    if (this.editData.id && this.editData.version) {
      this.formData.id = this.editData.id;
      this.formData.version = this.editData.version;
    }
    if (!this.editData.isNeedJr) {
      this.stockownertype.shift();
    }
  },
  methods: {
    formatTime(item, name, value) {
      item[name] = formatTime(value, 'YYYY-MM-DD');
    },
    getDefaultStorageId(orderId) {
      this.ajax({
        type: 'GET',
        url: `/malicrm/sm/deliver/getStorageByOrderId/${orderId}`,

        success: (res) => {
          if (res.code === 200) {
            const item = res.result;
            this.formData.storageId = item.storageId;
            this.formData.storageName = item.storageName;
            this.formData.storageAddressProvinceId = item.storageAddressProvinceId;
            this.formData.storageAddressProvince = item.storageAddressProvince;
            this.formData.storageAddressDistrictId = item.storageAddressDistrictId;
            this.formData.storageAddressDistrict = item.storageAddressDistrict;
            this.formData.storageAddressCityId = item.storageAddressCityId;
            this.formData.storageAddressCity = item.storageAddressCity;
            this.formData.storageAddressDetail = item.storageAddressDetail;

            this.getcoldlist();
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    clearForm() {
      this.$refs.form.clearValidate();
    },
    changeinput(value) {
      this.formData.declarationNo = value.replace(/[^\d.,<>?{};':"|!@#$%^&*()_+-=+/]/g, ''); // 清除“数字”和“.”以外的字符
    },
    inspecinput(value) {
      this.formData.inspectionNo = value.replace(/[^\d.,<>?{};':"|!@#$%^&*()_+-=+/]/g, ''); // 清除“数字”和“.”以外的字符
    },
    // 获取冷库数据
    getcoldlist(name = '') {
      this.ajax({
        type: 'POST',
        url: '/malicrm/storage/queryByFullName',
        data: {
          storageName: name,
        },
        success: (res) => {
          if (res.code === 200) {
            // this.successTip('1111');
            this.coldlist = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/deliverDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.formData.deliverDate = res.result.deliverDate;
            this.formData.deliverMode = res.result.deliverMode;
            this.formData.declarationNo = res.result.declarationNo;
            this.formData.inspectionNo = res.result.inspectionNo;
            this.formData.storageName = res.result.storageName;
            this.formData.inspectMode = res.result.inspectMode;
            this.formData.ownerType = res.result.ownerType;
            this.formData.storageId = res.result.storageId;
            this.formData.deliverAddress = res.result.deliverAddress;
            this.formData.settleDate = res.result.settleDate;
            this.formData.storageAddressProvinceId = res.result.storageAddressProvinceId;
            this.formData.storageAddressProvince = res.result.storageAddressProvince;
            this.formData.storageAddressDistrictId = res.result.storageAddressDistrictId;
            this.formData.storageAddressDistrict = res.result.storageAddressDistrict;
            this.formData.storageAddressCityId = res.result.storageAddressCityId;
            this.formData.storageAddressCity = res.result.storageAddressCity;
            this.formData.storageAddressDetail = res.result.storageAddressDetail;
            this.formData.deliverIssue = res.result.deliverIssue;
            this.formData.id = res.result.id;
            this.formData.ownerName = res.result.ownerName;
            this.formData.version = res.result.version;
            this.formData.xhfwSettleStartDate = res.result.xhfwSettleStartDate;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    selectstorage() {
      this.coldlist.forEach((item) => {
        if (item.id === this.formData.storageId) {
          this.formData.storageName = item.shortName;
          this.formData.storageAddressProvinceId = item.provinceId;
          this.formData.storageAddressProvince = item.addressProvince;
          this.formData.storageAddressDistrictId = item.areaId;
          this.formData.storageAddressDistrict = item.addressDistrict;
          this.formData.storageAddressCityId = item.cityId;
          this.formData.storageAddressCity = item.addressCity;
          this.formData.storageAddressDetail = item.addressDetail;
        }
      });
    },
    ownerTypechange() {
      if (this.formData.ownerType === 1) {
        this.formData.ownerName = this.editData.fundingShortName;
      }
      if (this.formData.ownerType === 2) {
        this.formData.ownerName = this.editData.consigneeShortName;
      }
      if (this.formData.ownerType === 3) {
        this.formData.ownerName = this.editData.customerName;
      }
    },
    changeAutoCertNo(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符
      this.formData.autoCertNo = value;
    },
    /**
     * 检查小数位数
     * @param e 时间
     * @param name 属性名
     * @param len 最大整数位数
     * @param dot 截取位数
     */
    changeDecimal(e, name, len, dot) {
      let value = e.target.value;
      // 格式化汇率格式
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= len) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, dot);
        }
      }
      // 只能输入两个小数
      this.formData[name] = value;
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          clearTimeout(this.saveBtnTimer);
          this.saveBtnTimer = setTimeout(() => {
            this.saveSubmitForm();
          }, 800);
        }
      });
    },

    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/deliver/operateDeliver',
        loading: true,
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.make_ownerName {
  border: 1px solid rgba(220, 223, 230, 1);
  height: 40px;
  line-height: 28px;
  padding: 5px;
  width: 301px;
  padding-left: 10px;
  color: #303133;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
