
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import makedelform from './makedelform.vue';
import confirmform from './confirmDelform.vue';
import confirmaddform from './confirmaddform.vue';
import makedetail from './makedetail.vue';
import updateFile from './updateFile.vue';
import auditwarehousing from './auditwarehousing.vue';
import confirmdeldetail from './confirmdeldetail.vue';
import confirmadddetail from './confirmadddetail.vue';
import { erpConfirm, errorMessage, errorTip, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';

export default defineComponent({
  name: 'deliveryList',
  components: {
    ErpList,
    makedelform,
    confirmform,
    confirmdeldetail,
    makedetail,
    confirmadddetail,
    confirmaddform,
    auditwarehousing,
    updateFile,
  },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const data = reactive({
      formType: 1,
      productData: [],
      type: 1,
      editData: {},
    });

    const formData = reactive({
      invoiceVariety: '1',
    });
    const filterDialog = ref(false);

    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };

    // 导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/sm/deliver/exportDeliver',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    const successCallback = (row) => {
      console.log(row);
      data.formType = 1;
      cancel(row);
    };

    // 查看详情
    const rowClick = (row, column) => {
      if (column.label !== '操作') {
        data.editData = row;
        // 预约查看
        if (row.deliverStatus === 2 || row.deliverStatus === 3) {
          data.type = 4;

          showVisible();
        }
        // 查看直送
        if (row.deliverStatus === 4) {
          data.type = 5;

          showVisible();
        }
        // 查看入库
        if (row.deliverStatus === 5) {
          data.editData = row;
          data.type = 6;
          showVisible();
        }
        // 查看待审核入库
        if (row.deliverStatus === 6) {
          data.type = 8;
          data.formType = 8;
          showVisible();
        }
      }
    };

    // 预约送货
    const makedelivery = (row) => {
      if (Number(row.packageCount) <= 0) {
        errorTip('当前订单件数为0，无法预约送货，请先按照国外单据填写件数。');
      } else {
        data.editData = row;
        data.type = 1;
        data.formType = 1;
        showVisible();
      }
    };
    // 确认直送
    const confirmDelivery = (row) => {
      data.editData = row;
      data.type = 2;
      showVisible();
    };
    // 审核
    const auditwarehousing = (row) => {
      data.editData = row;
      data.type = 7;
      data.formType = 7;
      showVisible();
    };
    // 确认入库
    const confirmaddContract = (row) => {
      data.editData = row;
      data.type = 3;
      showVisible();
    };
    const updataFile = (row) => {
      data.editData = row;
      data.type = 9;
      showVisible();
    };
    const editCallback = () => {
      data.type = 1;
      data.formType = 2;
    };

    return {
      editCallback,
      successCallback,
      makedelivery,
      confirmDelivery,
      auditwarehousing,
      confirmaddContract,
      filterDialog,
      formData,
      ...toRefs(data),
      exportFileFn,
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      updataFile,
    };
  },
});
