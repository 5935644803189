<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">
        {{ formType === 7 ? '审核入库' : '查看待审核入库' }}
      </div>
      <div class="erp-form-bar">
        <div
          class="erp-primary__btn"
          v-if="hasPermission('erp:order:saveAutoCert') && formType == 7"
          @click="btnAudit(5)"
        >
          审核通过
        </div>
        <div class="erp-primary__btn" v-if="formType == 7" @click="btnAudit(3)">
          审核不通过
        </div>
        <div class="erp-primary__btn" v-if="formType == 8" @click="btnCancel()">
          取消
        </div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp op-16">
      <el-row :gutter="30" type="flex" class="flex-warp">
        <el-col :span="12">
          <erp-view label="入库日期" :value="formData.deliverDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view
            label="送货方式"
            :value="formData.deliverModeName"
          ></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="冷库" :value="formData.storageName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="货主" :value="formData.ownerName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view
            label="结算起始日"
            :value="formData.xhfwSettleStartDate"
          ></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="送货问题" :value="formData.deliverIssue"></erp-view>
        </el-col>
      </el-row>
    </div>
    <div
      class="confirmadd__title op-16"
      style="margin-top: 20px; margin-left: 16px"
    >
      产品信息
    </div>
    <div class="claim_company">
      <el-table
        :data="productList"
        :show-summary="showSummary"
        :summary-method="summaryFn"
        border
        class="egrid erp-table__list erp-view__table"
      >
        <el-table-column
          label="货物信息"
          prop="plantNo"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="488"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.joinName) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="单价"
          prop="unitPrice"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="120"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.unitPriceDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="重量(kg)"
          prop="contractWeight"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="120"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.contractWeightDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="件数"
          prop="packageCount"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="90"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.packageCountDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="入库件数"
          prop="instoreCount"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="90"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.instoreCountDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="入库重量"
          prop="instoreWeight"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="90"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.instoreWeightDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="实际货值"
          prop="actualValue"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="130"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.actualValueDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="合同货值"
          prop="contractValue"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="120"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.contractValueDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="货值差"
          prop="valueDiff"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="110"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.valueDiffDisplay) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="font-size: 12px; color: #f04737; padding: 7px 16px 0 16px">
      注：如果入库重量和合同重量相差较大，需要签补充协议时，才需填写本表。
    </div>
    <div class="confirmadd__title op-16" style="margin: 20px 0 0 16px">
      入库文件
    </div>
    <div class="op-16">
      <el-row :gutter="30" type="flex" class="flex-warp">
        <el-col :span="12">
          <erp-view
            label="入库单号"
            :value="formData.instoreReceiptNo"
          ></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="入库单文件">
            <div style="flex: 1">
              <div
                class="card-preview"
                v-for="(item, index) in crmAttachmentVo"
                :key="index"
              >
                <span>{{ item.name }}</span>
                <img
                  src="../../../../assets/images/preview__zoom.png"
                  alt
                  @click="$showFiles(item.name, item.url)"
                />
                <img
                  src="../../../../assets/images/preview_download.png"
                  alt
                  @click="$showFiles(item.name, item.url, false)"
                />
              </div>
            </div>
          </erp-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      formData: {
        declarationNo: 'AAAAA',
        deliverDate: '2019-10-14',
        deliverIssue: '打飞机阿里富家大室了飞机第三方',
        deliverMode: '1',
        deliverModeName: '入库',
        id: 0,
        inspectMode: '2',
        inspectModeName: '动检',
        inspectionNo: '111',
        instoreReceiptNo: '3432423',
        isAbnormal: true,
        orderId: 0,
        ownerName: '洪迈',
        ownerType: 'string',
        ownerTypeName: 'string',
        stockProductVos: [
          {
            actualValue: 1343,
            actualValueDisplay: '1,343',
            actualWeight: 1350,
            actualWeightDisplay: '2,350',
            category: '牛肉',
            contractValue: 50,
            contractValueDisplay: '50',
            contractWeight: 1110,
            contractWeightDisplay: '1,110',
            country: '澳大利亚',
            instoreCount: 2000,
            instoreCountDisplay: '2,000',
            instoreWeight: 56000,
            instoreWeightDisplay: '56,000',
            joinName: '牛_新西兰_ME47_去盖臀肉_FROZEN BONELESS BEEF THIN SKIRT',
            nameCn: '去盖臀肉',
            nameEn: 'FROZEN BONELESS BEEF THIN SKIRT',
            overShort: -2,
            packageCount: 3000,
            packageCountDisplay: '3,000',
            plantNo: 'ME47',
            unitPrice: 80,
            unitPriceDisplay: '80',
            unitWeight: 0,
            valueDiff: 6300,
            valueDiffDisplay: '6,300',
          },
          {
            actualValue: 1343,
            actualValueDisplay: '1,343',
            actualWeight: 1350,
            actualWeightDisplay: '2,350',
            category: '牛肉',
            contractValue: 50,
            contractValueDisplay: '50',
            contractWeight: 1110,
            contractWeightDisplay: '1,110',
            country: '新西兰',
            instoreCount: 2000,
            instoreCountDisplay: '2,000',
            instoreWeight: 5600,
            instoreWeightDisplay: '5,600',
            joinName:
              '牛_新西兰_ME47_冷冻牛板腱_FROZEN BONELESS BEEF THIN SKIRT',
            nameCn: '冷冻牛板腱',
            nameEn: 'FROZEN BONELESS BEEF THIN SKIRT',
            overShort: -2,
            packageCount: 3100,
            packageCountDisplay: '3,100',
            plantNo: 'ME47',
            unitPrice: 980,
            unitPriceDisplay: '980',
            unitWeight: 0,
            valueDiff: 6320,
            valueDiffDisplay: '6,320',
          },
        ],
        storageName: '上海市浦东新区欣阳路120号',
        totalActual: 0,
        totalActualValue: 0,
        totalContractValue: 0,
        totalCount: 0,
        totalInstoreCount: 0,
        totalInstoreWeight: 0,
        totalValueDiff: 0,
        totalWeight: 0,
        version: 1,
        xhfwSettleStartDate: '2019-10-14',
      },
      productList: [],
      crmAttachmentVo: [],
      showSummary: false,
    };
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
  created() {
    this.initDetailData();
  },
  methods: {
    /**
     * 合计行
     * @returns {[string,string,string,string,string,string,string,string]}
     */
    summaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', '', ''];
      const data = this.productList;
      // 重量
      let contractWeightTotal = data.reduce((total, item) => {
        const contractWeightTotal = Number(item.contractWeight);
        return total + contractWeightTotal;
      }, 0);
      contractWeightTotal = this._formatFloat(contractWeightTotal, 2);
      // 件数
      let packageCountTotal = data.reduce((total, item) => {
        const packageCountTotal = Number(item.packageCount);
        return total + packageCountTotal;
      }, 0);
      if (isNaN(packageCountTotal)) {
        packageCountTotal = 0;
      }
      // 入库件数
      let instoreCountTotal = data.reduce((total, item) => {
        const instoreCountTotal = Number(item.instoreCount);
        return total + instoreCountTotal;
      }, 0);
      if (isNaN(instoreCountTotal)) {
        instoreCountTotal = 0;
      }

      // 入库重量
      let instoreWeightTotal = data.reduce((total, item) => {
        const instoreWeightTotal = Number(item.instoreWeight);
        return total + instoreWeightTotal;
      }, 0);
      instoreWeightTotal = this._formatFloat(instoreWeightTotal, 2);
      // 实际货值
      let totalAmountTotal = data.reduce((total, item) => {
        const totalAmountTotal = Number(item.actualValue);
        return total + totalAmountTotal;
      }, 0);
      totalAmountTotal = this._formatFloat(totalAmountTotal, 2);
      // 合同货值
      let contractValueTotal = data.reduce((total, item) => {
        const contractValueTotal = Number(item.contractValue);
        return total + contractValueTotal;
      }, 0);
      contractValueTotal = this._formatFloat(contractValueTotal, 2);
      // 货值差
      let valueDiffTotal = data.reduce((total, item) => {
        const valueDiffTotal = Number(item.valueDiff);
        return total + valueDiffTotal;
      }, 0);
      valueDiffTotal = this._formatFloat(valueDiffTotal, 2);
      summaryColumns[2] = this._thousandBitSeparator(contractWeightTotal); // 重量
      summaryColumns[3] = this._thousandBitSeparator(packageCountTotal); // 件数
      summaryColumns[4] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
      summaryColumns[5] = this._thousandBitSeparator(instoreWeightTotal); // 入库重量
      summaryColumns[6] = this._thousandBitSeparator(totalAmountTotal); // 实际货值
      summaryColumns[7] = this._thousandBitSeparator(contractValueTotal); // 合同货值
      summaryColumns[8] = this._thousandBitSeparator(valueDiffTotal); // 实际货值
      return summaryColumns;
    },
    /**
     * 初始化确认入库信息
     */
    initDetailData() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/deliverDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.productList = this.formData.sumVo.productVos;
            this.crmAttachmentVo = this.formData.attachmentVos;
            if (this.productList.length > 1) {
              this.showSummary = true;
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     * 审核
     * @param status 状态 审核状态：3：审核不通过 5：审核通过 ,
     */
    btnAudit(status) {
      if (status === 5) {
        this.$erpConfirm('确定审核通过吗？')
          .then(() => {
            this.ajax({
              type: 'POST',
              url: '/malicrm/sm/deliver/auditInStore',
              loading: true,
              data: {
                id: this.formData.id,
                status: status,
                version: this.formData.version,
              },
              success: (res) => {
                if (res.code === 200) {
                  this.successTip('保存成功');
                  this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
                } else {
                  this.errorTip(res.message);
                }
              },
            });
          })
          .catch(() => {
            return false;
          });
      } else {
        this.ajax({
          type: 'POST',
          url: '/malicrm/sm/deliver/auditInStore',
          loading: true,
          data: {
            id: this.formData.id,
            status: status,
            version: this.formData.version,
          },
          success: (res) => {
            if (res.code === 200) {
              this.successTip('保存成功');
              this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
    },
    /**
     * 取消审核入库
     */
    btnCancel() {
      this.$erpConfirm('确定要取消本次确认入库吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/sm/deliver/cancelInStore',
            loading: true,
            data: {
              id: this.formData.id,
              version: this.formData.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('保存成功');
                this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-preview {
  margin-bottom: 16px;
}

.card-preview:last-child {
  margin-bottom: 0;
}

.viewform__one_body {
  display: flex;
  /*flex: 1;*/
  width: 50%;
}

.viewform__main {
  display: flex;
  flex-direction: row;
}

.padding__top {
  padding-top: 20px;
}

.viewfrom_body_title {
  display: flex;
  width: 90px;
  color: rgba(115, 124, 140, 1);
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}

.viewfrom_body_value {
  display: flex;
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 15px;
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}

.viewfrom_body {
  width: 100%;
  min-height: 42px;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
}
</style>
