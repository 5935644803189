/* eslint-disable @typescript-eslint/no-this-alias */
<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">确认入库</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="saveData">保存</div>
        <div class="erp-primary__btn" @click="submit">确认入库</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body no-padding">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form" style="width: 100%">
          <!--期货确认入库画面-->
          <el-row v-if="editData.category === 1" :gutter="16">
            <el-col :span="12">
              <el-form-item label="入库日期" prop="autoCertNo">
                <el-date-picker
                  v-model="formData.deliverDate"
                  type="date"
                  :disabled="true"
                  placeholder="请选择入库日期"
                  prefix-icon=" "
                  style="width: 100%"
                  @change="formatTime(formData, 'deliverDate', $event)"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="送货方式" prop="deliverModeName">
                <el-input :maxlength="8" v-model="formData.deliverModeName" clearable :disabled="true" placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报关单号" prop="unitPrice">
                <el-input :maxlength="40" v-model="formData.declarationNo" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报检号" prop="weight">
                <el-input :maxlength="40" v-model="formData.inspectionNo" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="冷库" prop="weight">
                <el-input :maxlength="8" v-model="formData.storageName" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检验方式" prop="weight">
                <el-input :maxlength="8" v-model="formData.inspectModeName" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="货主" prop="weight">
                <el-input :maxlength="8" v-model="formData.ownerName" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="送货问题" prop="remark">
                <el-input
                  v-model="formData.deliverIssue"
                  placeholder="请输入送货问题"
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--现货确认入库-->
          <el-row v-if="editData.category !== 1" :gutter="16">
            <el-col :span="12">
              <el-form-item label="入库日期" prop="deliverDate">
                <el-date-picker
                  v-model="formData.deliverDate"
                  type="date"
                  :disabled="true"
                  placeholder="请选择入库日期"
                  prefix-icon=" "
                  style="width: 100%"
                  @change="formatTime(formData, 'deliverDate', $event)"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="送货方式" prop="deliverModeName">
                <el-input :maxlength="8" v-model="formData.deliverModeName" clearable :disabled="true" placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="冷库" prop="storageName">
                <el-input :maxlength="8" v-model="formData.storageName" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="货主" prop="ownerName">
                <el-input :maxlength="8" v-model="formData.ownerName" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结算起始日" prop="xhfwSettleStartDate">
                <el-date-picker
                  v-model="formData.xhfwSettleStartDate"
                  :disabled="true"
                  type="date"
                  placeholder="-"
                  prefix-icon=" "
                  style="width: 100%"
                  @change="formatTime(formData, 'xhfwSettleStartDate', $event)"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="送货问题" prop="deliverIssue">
                <el-input
                  v-model="formData.deliverIssue"
                  placeholder="请输入送货问题"
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div style="margin-top: 10px; margin-left: 20px" class="order-title__bar">
            <div class="erp-form__title f16">产品信息</div>
            <div class="edit-btn__bar">
              <span class="edit-btn" @click="btnIsAbnormal" v-if="editData.category !== 1">
                <img src="../../../../assets/images/icon-form__tab.png" alt style="margin-right: 3px; position: relative; top: -1px" />
                {{ formData.clickTitle }}
              </span>
            </div>
          </div>
          <div>
            <!--期货产品信息-->
            <el-table
              :show-summary="showSummary"
              v-if="editData.category === 1"
              :summary-method="qhSummaryFn"
              :data="productList"
              border
              class="egrid erp-table__list product_table_total erp-view__table"
            >
              <el-table-column label="厂号" prop="plantNo" align="center" class-name="no-pd" show-overflow-tooltip width="90">
                <template #default="scope">
                  <div>{{ filterName(scope.row.plantNo) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="中文品名" prop="nameCn" class-name="no-pd importnice_cn" align="center" show-overflow-tooltip width="200">
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.nameCn) }}
                  </div>
                  <el-autocomplete
                    v-model="scope.row.nameCn"
                    :fetch-suggestions="queryNameCnSearchAsyncCn"
                    :popper-append-to-body="true"
                    :disabled="scope.row.labelId && scope.row.labelId !== ''"
                    @select="handleName($event, scope.row)"
                    @blur="nameFocus(scope.row)"
                    @focus="getPlantNo(scope.row)"
                    v-else
                    class="confirmaadd"
                    style="width: 100%"
                    placement="bottom"
                    popper-class="nameCn-list"
                    value-key="plantNoNameCn"
                  ></el-autocomplete>
                  <span class="el-icon-circle-close name-close" @click="clearNameLabel(scope.row)" v-if="scope.row.labelId && scope.row.labelId !== ''"></span>
                </template>
              </el-table-column>
              <el-table-column label="英文品名" width="260" prop="nameEn" class-name="no-pd" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.nameEn) }}
                  </div>
                  <el-autocomplete
                    v-model="scope.row.nameEn"
                    :fetch-suggestions="queryNameCnSearchAsyncEn"
                    :popper-append-to-body="true"
                    :disabled="scope.row.labelId && scope.row.labelId !== ''"
                    @select="handleName($event, scope.row)"
                    @blur="nameFocus(scope.row)"
                    @focus="getPlantNo(scope.row)"
                    v-else
                    class="confirmaadd"
                    style="width: 100%"
                    value-key="plantNoNameEn"
                    popper-class="nameEn-list"
                  ></el-autocomplete>
                  <span class="el-icon-circle-close name-close" @click="clearNameLabel(scope.row)" v-if="scope.row.labelId && scope.row.labelId !== ''"></span>
                </template>
              </el-table-column>
              <el-table-column label="实际净重(kg)" prop="actualWeight" class-name="no-pd importnice_wekg" align="center" show-overflow-tooltip width="125">
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.actualWeight) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.actualWeight"
                    placeholder
                    :maxlength="9"
                    class="confirmaadd"
                    @input="changePackCount($event, scope.row)"
                    @blur="bluePackCount($event, scope.row)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="件数" prop="packageCount" class-name="no-pd" align="center" show-overflow-tooltip width="100">
                <template #default="scope">
                  <div>{{ filterName(scope.row.packageCount) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="入库件数" prop="instoreCount" align="center" class-name="no-pd " show-overflow-tooltip>
                <template v-slot:header>
                  <span class="erp-req">入库件数</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isShowInput">
                    {{ filterName(scope.row.instoreCount) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.instoreCount"
                    class="confirmaadd"
                    placeholder
                    @input="Packstoreinput($event, scope.row)"
                    :maxlength="4"
                  ></el-input>
                </template>
              </el-table-column>

              <el-table-column fixed="right" width="48" class-name="no-pd" align="center">
                <template v-slot:header="scope">
                  <span class="el-icon-circle-plus" style="font-size: 18px; vertical-align: middle" @click="addProduct(scope.$index)"></span>
                </template>
                <template #default="scope">
                  <span class="list-del" v-if="!scope.row.isshow" @click="deleteProduct(scope.$index)">
                    <img src="../../../../assets/images/list-del.png" alt />
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="claim_company storage" v-if="editData.category !== 1 && formData.isAbnormal">
            <!--现货产品信息  异常切换-->
            <el-table
              :show-summary="showSummary"
              :summary-method="xhSummaryFn"
              :data="productList"
              ref="tableLayOut"
              border
              id="tables"
              :class="{ 'no-shadow': !shadow }"
              class="egrid erp-table__list product_table_total erp-view__table"
            >
              <el-table-column label="货物信息" prop="joinName" align="center" class-name="no-pd" show-overflow-tooltip width="528">
                <template v-slot:header>
                  <span class="erp-req">货物信息</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.joinName) }}
                  </div>
                  <!-- 现货产品添加 -->
                  <div class="table-goods-wrap" v-else @click="clickProductInfo(scope.row, scope.$index)">
                    <div class="table-goods-info">{{ scope.row.joinName }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价" prop="unitPrice" class-name="no-pd " align="center" show-overflow-tooltip width="120">
                <template v-slot:header>
                  <span class="erp-req">单价</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.unitPrice) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.unitPrice"
                    placeholder
                    :maxlength="10"
                    class="confirmaadd"
                    @blur="changeDecimal($event, scope.$index, 'unitPrice', 10000, 4)"
                  >
                  </el-input>
                </template>
              </el-table-column>

              <el-table-column label="重量(kg)" prop="contractWeight" class-name="no-pd" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <!--异常入库没有重量输入框 -->
                  <div>
                    {{ filterName(scope.row.contractWeightDisplay) }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="件数" prop="packageCount" class-name="no-pd" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <div>{{ filterName(scope.row.packageCount) }}</div>
                </template>
              </el-table-column>

              <el-table-column label="入库件数" prop="instoreCount" align="center" class-name="no-pd" show-overflow-tooltip width="100">
                <template v-slot:header>
                  <span class="erp-req">入库件数</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isShowInput">
                    {{ filterName(scope.row.instoreCount) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.instoreCount"
                    placeholder
                    :maxlength="4"
                    class="confirmaadd"
                    style="width: 100%"
                    @blur="changeNumber($event, scope.$index, 'instoreCount')"
                  ></el-input>
                </template>
              </el-table-column>

              <el-table-column label="入库重量(kg)" prop="instoreWeight" align="center" class-name="no-pd " show-overflow-tooltip width="110">
                <template v-slot:header>
                  <span class="erp-req">入库重量(kg)</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isShowInput">
                    {{ filterName(scope.row.instoreWeight) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.instoreWeight"
                    placeholder
                    :maxlength="9"
                    class="confirmaadd"
                    @blur="changeDecimal($event, scope.$index, 'instoreWeight', 1000000, 6)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="实际货值" prop="actualValue" align="center" class-name="no-pd" show-overflow-tooltip width="120">
                <template #default="scope">
                  <div>{{ filterName(scope.row.actualValueDisplay) }}</div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="48" class-name="no-pd" align="center">
                <template v-slot:header="scope">
                  <span class="el-icon-circle-plus" style="font-size: 18px; vertical-align: middle" @click="addProduct(scope.$index)"></span>
                </template>
                <template #default="scope">
                  <span class="list-del" v-if="!scope.row.isshow" @click="deleteProduct(scope.$index)">
                    <img src="../../../../assets/images/list-del.png" alt />
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 正常入库列表展示 -->
          <div v-if="editData.category !== 1 && !formData.isAbnormal">
            <el-table
              :show-summary="showSummary"
              :summary-method="xhSummaryFn"
              :data="productList"
              border
              class="egrid erp-table__list product_table_total erp-view__table"
              ref="tableLayOut"
            >
              <el-table-column label="货物信息" prop="joinName" align="center" class-name="no-pd" show-overflow-tooltip width="638">
                <template v-slot:header>
                  <span class="erp-req">货物信息</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.joinName) }}
                  </div>
                  <!-- 现货产品添加 -->
                  <div class="table-goods-wrap" v-else @click="clickProductInfo(scope.row, scope.$index)">
                    <div class="table-goods-info">{{ scope.row.joinName }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="重量(kg)" prop="contractWeight" class-name="no-pd" align="center" show-overflow-tooltip width="120">
                <template v-slot:header>
                  <span class="erp-req">重量(kg)</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isshow">
                    {{ filterName(scope.row.contractWeightDisplay) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.contractWeight"
                    placeholder
                    :maxlength="8"
                    class="confirmaadd"
                    @blur="changeDecimal($event, scope.$index, 'contractWeight', 1000000, 6)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="件数" prop="packageCount" class-name="no-pd" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <div>{{ filterName(scope.row.packageCount) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="入库件数" prop="instoreCount" align="center" class-name="no-pd" show-overflow-tooltip>
                <template v-slot:header>
                  <span class="erp-req">入库件数</span>
                </template>
                <template #default="scope">
                  <div v-if="scope.row.isShowInput">
                    {{ filterName(scope.row.instoreCount) }}
                  </div>
                  <el-input
                    v-else
                    v-model="scope.row.instoreCount"
                    placeholder
                    :maxlength="4"
                    class="confirmaadd"
                    style="width: 100%"
                    @blur="changeNumber($event, scope.$index, 'instoreCount')"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="48" class-name="no-pd" align="center">
                <template v-slot:header="scope">
                  <span class="el-icon-circle-plus" style="font-size: 18px; vertical-align: middle" @click="addProduct(scope.$index)"></span>
                </template>
                <template #default="scope">
                  <span class="list-del" v-if="!scope.row.isshow" @click="deleteProduct(scope.$index)">
                    <img src="../../../../assets/images/list-del.png" alt />
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <!-- 这个判断没有做 -->
          </div>
          <div v-if="formData.isAbnormal" style="color: #f04737; font-size: 12px; padding: 7px 0 0 16px">
            注：如果入库重量和合同重量相差较大，需要签补充协议时，才需填写本表。
          </div>

          <!--入库文件-->
          <div>
            <el-col :span="24">
              <div class="confirmadd__title" style="margin-top: 28px">入库文件</div>
              <div class="infor_doc_main">
                <div class="infor_doc_title">入 库 单 号</div>
                <div class="infor_doc_body">
                  <el-input v-model="formData.instoreReceiptNo" style="margin-left: 0px" class="infor_doc_input" placeholder :maxlength="30"></el-input>
                </div>
              </div>
              <div class="border_stock_add" style="border: 1px solid rgba(220, 223, 230, 1); margin: 17px 0; padding: 16px">
                <SingleFileContent :fileType="1" :fromType="1" @success="getContractUrls" :max="3" :customData="attachmentType" :fileList="fileUrlsArray">
                </SingleFileContent>
              </div>
            </el-col>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 现货产品添加 -->
    <product-dialog :isShow="isShowProductDialog" @on-close="closeProductDialog">
      <div v-if="isShowProductDialog">
        <product-pop2
          :required="true"
          :showType="2"
          :itemIndex="itemIndex"
          @changePopItem="changePopItem"
          :productDialogData="productDialogData"
          @on-close="closeProductDialog"
        >
        </product-pop2>
      </div>
    </product-dialog>
    <!--现货产品添加  -->
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import productMixin2 from '@/views/crm/opportunity/components/CommonProductCom/productMixin2';
import productDialog from '@/views/crm/opportunity/components/CommonProductCom/productDialog.vue';
import productPop2 from '@/views/crm/opportunity/components/CommonProductCom/productPop2.vue';
import { formatTime } from '@/utils/publicMethods';
export default {
  mixins: [productMixin2],
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      shadow: false,
      fileUrlsArray: [],
      isShowProductDialog: false, // 现货产品添加
      itemIndex: false, // 现货产品添加
      productDialogData: null, // 现货产品添加
      isLoading: false,
      writtenOffList: [
        {
          value: 0,
          label: '直送',
        },
        {
          value: 1,
          label: '入库',
        },
      ],
      attachmentType: {
        // 选择类型
        label: null,
        value: null,
      },
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt,.xls,.xlsx,.zip,.PDF,.DOC,.DOCX,.JPG,.PNG,.GIF,.TXT,.XLS,.XLSX,.ZIP',
      fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx,zip   大小上限：30M',
      formData: {
        clickTitle: '切换异常入库',
        declarationNo: 'AAAAA',
        deliverDate: '2019-10-14',
        deliverIssue: '打飞机阿里富家大室了飞机第三方',
        deliverMode: '1',
        deliverModeName: '入库',
        id: 0,
        inspectMode: '2',
        inspectModeName: '动检',
        inspectionNo: '111',
        instoreReceiptNo: '3432423',
        isAbnormal: false,
        orderId: 0,
        ownerName: '洪迈',
        ownerType: 'string',
        ownerTypeName: 'string',
        attachmentVos: [],
        stockProductVos: [
          {
            actualValue: 1343,
            actualValueDisplay: '1,343',
            actualWeight: 1350,
            actualWeightDisplay: '2,350',
            category: 1,
            contractValue: 50,
            contractValueDisplay: '50',
            contractWeight: 1110,
            contractWeightDisplay: '1,110',
            country: '澳大利亚',
            instoreCount: 2000,
            instoreCountDisplay: '2,000',
            instoreWeight: 56000,
            instoreWeightDisplay: '56,000',
            joinName: '牛_新西兰_SIF177_去盖臀肉_FROZEN BONELESS BEEF THIN SKIRT',
            nameCn: '去盖臀肉',
            nameEn: 'FROZEN BONELESS BEEF THIN SKIRT',
            overShort: -2,
            packageCount: 3000,
            packageCountDisplay: '3,000',
            plantNo: 'SIF177',
            unitPrice: 80,
            unitPriceDisplay: '80',
            unitWeight: 0,
            valueDiff: 6300,
            isshow: true,
            isShowInput: false,
            valueDiffDisplay: '6,300',
          },
          {
            actualValue: 1343,
            actualValueDisplay: '1,343',
            actualWeight: 1350,
            actualWeightDisplay: '2,350',
            category: 1,
            contractValue: 50,
            contractValueDisplay: '50',
            contractWeight: 1110,
            contractWeightDisplay: '1,110',
            country: '新西兰',
            instoreCount: 2000,
            instoreCountDisplay: '2,000',
            instoreWeight: 5600,
            instoreWeightDisplay: '5,600',
            joinName: '牛_新西兰_ME47_冷冻牛板腱_FROZEN BONELESS BEEF THIN SKIRT',
            nameCn: '冷冻牛板腱',
            nameEn: 'FROZEN BONELESS BEEF THIN SKIRT',
            overShort: -2,
            packageCount: 3100,
            packageCountDisplay: '3,100',
            plantNo: 'ME47',
            unitPrice: 980,
            unitPriceDisplay: '980',
            unitWeight: 0,
            valueDiff: 6320,
            isshow: true,
            isShowInput: false,
            valueDiffDisplay: '6,320',
          },
        ],
        storageName: '上海市浦东新区欣阳路120号',
        totalActual: 0,
        totalActualValue: 0,
        totalContractValue: 0,
        totalCount: 0,
        totalInstoreCount: 0,
        totalInstoreWeight: 0,
        totalValueDiff: 0,
        totalWeight: 0,
        version: 1,
        xhfwSettleStartDate: '2019-10-14',
      },
      productPlantNo: '',
      showSummary: true,
      productList: [], // 产品信息集合
      formAttachmentList: [], // 上传保存用上传列表
      rules: {},
    };
  },

  computed: {
    canListen() {
      return this.editData.category !== 1 && this.formData.isAbnormal;
    },
  },
  watch: {
    canListen(newval) {
      if (newval) {
        this.$nextTick(function () {
          // 此时已经渲染完成
          this.shadow = true;
        });
      }
    },
  },
  created() {
    this.getinitdata();
  },

  methods: {
    formatTime(item, name, value) {
      item[name] = formatTime(value, 'YYYY-MM-DD');
    },
    clickProductInfo(item, index) {
      this.itemIndex = index;
      this.productDialogData = item;
      this.isShowProductDialog = true;
    },
    changePopItem(item, index) {
      // 获取到选中的产品
      item.joinName = item.goodsInfoFormat;
      this.productList[index] = item;
      this.isShowProductDialog = false;
    },
    closeProductDialog() {
      this.isShowProductDialog = false;
    },
    /**
     * 现货合计行
     * @returns {[string,string,string,string,string,string,string,string]}
     */
    xhSummaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', ''];
      const data = this.productList;
      // 重量
      let contractWeightTotal = data.reduce((total, item) => {
        const contractWeightTotal = Number(item.contractWeight);
        return total + contractWeightTotal;
      }, 0);
      contractWeightTotal = this._formatFloat(contractWeightTotal, 3);
      // 件数
      let packageCountTotal = data.reduce((total, item) => {
        const packageCountTotal = Number(item.packageCount);
        return total + packageCountTotal;
      }, 0);
      if (isNaN(packageCountTotal)) {
        packageCountTotal = 0;
      }
      // 入库件数
      let instoreCountTotal = data.reduce((total, item) => {
        const instoreCountTotal = Number(item.instoreCount);
        return total + instoreCountTotal;
      }, 0);
      if (isNaN(instoreCountTotal)) {
        instoreCountTotal = 0;
      }
      // false正常入库
      if (!this.formData.isAbnormal) {
        summaryColumns[1] = this._thousandBitSeparator(contractWeightTotal); // 重量
        summaryColumns[2] = this._thousandBitSeparator(packageCountTotal); // 件数
        summaryColumns[3] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
      } else {
        // 异常入库
        // 入库重量
        let instoreWeightTotal = data.reduce((total, item) => {
          const instoreWeightTotal = Number(item.instoreWeight);
          return total + instoreWeightTotal;
        }, 0);
        instoreWeightTotal = this._formatFloat(instoreWeightTotal, 3);
        // 实际货值
        let totalAmountTotal = data.reduce((total, item) => {
          const totalAmountTotal = Number(item.actualValue);
          return total + totalAmountTotal;
        }, 0);
        if (isNaN(totalAmountTotal)) {
          totalAmountTotal = 0;
        }

        totalAmountTotal = this._formatFloat(totalAmountTotal, 2);
        summaryColumns[2] = this._thousandBitSeparator(contractWeightTotal); // 重量
        summaryColumns[3] = this._thousandBitSeparator(packageCountTotal); // 件数
        summaryColumns[4] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
        summaryColumns[5] = this._thousandBitSeparator(instoreWeightTotal); // 入库重量
        summaryColumns[6] = this._thousandBitSeparator(totalAmountTotal); // 实际货值
      }
      return summaryColumns;
    },
    /**
     * 期货合计列
     * @returns {[string,string,string,string,string,string,string,string]}
     */
    qhSummaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', ''];
      const data = this.productList;
      // 实际净重
      let actualWeightTotal = data.reduce((total, item) => {
        const actualWeightTotal = Number(item.actualWeight);
        return total + actualWeightTotal;
      }, 0);
      actualWeightTotal = this._formatFloat(actualWeightTotal, 3);
      // 件数
      let packageCountTotal = data.reduce((total, item) => {
        const packageCountTotal = Number(item.packageCount);
        return total + packageCountTotal;
      }, 0);
      if (isNaN(packageCountTotal)) {
        packageCountTotal = 0;
      }
      // 入库件数
      let instoreCountTotal = data.reduce((total, item) => {
        const instoreCountTotal = Number(item.instoreCount);
        return total + instoreCountTotal;
      }, 0);
      if (isNaN(instoreCountTotal)) {
        instoreCountTotal = 0;
      }
      summaryColumns[3] = this._thousandBitSeparator(actualWeightTotal); // 实际净重
      summaryColumns[4] = this._thousandBitSeparator(packageCountTotal); // 件数
      summaryColumns[5] = this._thousandBitSeparator(instoreCountTotal); // 入库件数
      return summaryColumns;
    },

    Packstoreinput(data, row) {
      row.instoreCount = row.instoreCount.replace(/[^0-9]/gi, '');
      row.instoreCount = row.instoreCount.replace(/^0+\./g, '0.');
      row.instoreCount = row.instoreCount.match(/^0+[1-9]+/) ? row.instoreCount.replace(/^0+/g, '') : row.instoreCount;
    },
    getPlantNo(row) {
      // 获取当前点击厂号信息
      this.productPlantNo = [row.plantNo];
    },
    clearNameLabel(item) {
      item.labelId = null;
      item.nameCn = null;
      item.nameEn = null;
    },
    handleName(item) {
      // 获取英文品名
      // eslint-disable-next-line prefer-rest-params
      const data = arguments[1];
      data.nameCn = item.nameCn;
      data.nameEn = item.nameEn;
      data.category = item.category;
      data.country = item.country;
      data.plantNo = item.plantNo;
      data.labelId = item.id;
      data.labelUrl = item.url;
      data.varietyName = item.varietyName;
    },
    bluePackCount(data, row) {
      if (Number(row.actualWeight) > 99999) {
        row.actualWeight = 99999.999;
      }
      row.actualWeight = Number(row.actualWeight) ? row.actualWeight : '';
    },
    changePackCount(data, row) {
      row.actualWeight = row.actualWeight.replace(/[^\d.]/g, '');
      row.actualWeight = row.actualWeight.replace(/\.{2,}/g, '.');
      row.actualWeight = row.actualWeight.replace(/^\./g, '');
      row.actualWeight = row.actualWeight.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // eslint-disable-next-line
      row.actualWeight = row.actualWeight.replace(
        // eslint-disable-next-line no-useless-escape
        /^(\-)*(\d+)\.(\d\d\d).*$/,
        '$1$2.$3'
      );
      row.actualWeight = row.actualWeight.replace(/^0+\./g, '0.');
      row.actualWeight = row.actualWeight.match(/^0+[1-9]+/) ? row.actualWeight.replace(/^0+/g, '') : row.actualWeight;
    },
    nameFocus(item) {
      if (item.nameCn && item.nameEn) {
        return false;
      } else {
        item.nameCn = null;
        item.nameEn = null;
      }
    },
    /**
     * 获取确认入库详情信息
     */
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/sureInstoreData/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.formData.source = this.editData.category;
            if (res.result.attachmentVos.length > 0) {
              this.fileUrlsArray = res.result.attachmentVos;
              this.formData.attachmentDtos = res.result.attachmentVos;
            }
            if (this.formData.isAbnormal) {
              this.formData.clickTitle = '切换为正常入库';
            } else {
              this.formData.clickTitle = '切换为异常入库';
            }
            this.formData.stockProductVos.forEach((el, index, array) => {
              if (el.contractWeight !== null && el.contractWeight !== '') {
                array[index].isshow = true;
                array[index].isShowInput = false;
              }
              array[index].instoreCount = array[index].instoreCount === 0 ? null : array[index].instoreCount;
              this.productList.push(array[index]);
            });

            if (this.productList.length > 1) {
              this.showSummary = true;
            } else {
              this.showSummary = false;
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 中文远程搜索
    queryNameCnSearchAsyncCn(queryNameCn, cb) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.ajax({
        type: 'POST',
        url: '/malicrm/offer/queryOfferProductLabel',
        data: {
          category: this.productList[0].category, // 1 ,
          country: that.editData.country, // 19,
          nameCn: queryNameCn, // that.productForm.nameCn,
          nameEn: '', // that.productForm.nameEn,
          plantNo: [this.productList[0].plantNo], // plantNo
        },
        loading: false,
        isUnMusk: true,
        success: (res) => {
          if (res.code === 200) {
            that.searchList = res.result;
            if (that.searchList.length > 0) {
              that.searchList.forEach((item, index) => {
                that.searchList[index].value = that.searchList[index].nameCn;
              });
            }
            cb(that.searchList);
          }
        },
      });
    },
    // 英文远程搜索
    queryNameCnSearchAsyncEn(queryNameEn, cb) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.ajax({
        type: 'POST',
        url: '/malicrm/offer/queryOfferProductLabel',
        data: {
          category: this.productList[0].category, // 1 ,
          country: that.editData.country, // 19,
          nameCn: '', // that.productForm.nameCn,
          nameEn: queryNameEn, // that.productForm.nameEn,
          plantNo: this.productPlantNo, // plantNo
        },
        loading: false,
        isUnMusk: true,
        success: (res) => {
          if (res.code === 200) {
            that.searchList = res.result;
            if (that.searchList.length > 0) {
              that.searchList.forEach((item, index) => {
                that.searchList[index].value = that.searchList[index].nameEn;
              });
            }
            cb(that.searchList);
          }
        },
      });
    },
    getContractUrls(data) {
      // 获取到上传的文件
      let attachmentList = [];
      data.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      // 修改文件名
      attachmentList = this.formatFileName(attachmentList);
      this.fileUrlsArray = attachmentList;
      console.log(this.fileUrlsArray);
      this.formData.attachmentDtos = attachmentList;
    },
    /**
     * 修改文件名
     * @param fileArr 上传组件返回的文件数组[{name, url}]
     */
    formatFileName(fileArr) {
      const data = JSON.parse(JSON.stringify(fileArr));
      // 1.如果是旧的订单，则#销售合同号尾号(例如721-3)#-#证件类型#
      // 2.如果是新的订单，则#销售合同号客户编号(例如6003-0003)#-#合同编号#-#证件类型#
      // 销售合同号尾号 businessContractNo
      // 销售合同号客户编号 customerContractNo
      let { isNewOrder, businessContractNo, customerContractNo } = this.formData;
      const type = '入库单'; // 证件类型
      let index;
      if (isNewOrder) {
        // 新订单
        index = this.findChart(customerContractNo, '-', 1);
        businessContractNo = customerContractNo.substring(index + 1);
      } else {
        // 旧订单
        index = this.findChart(businessContractNo, '-', 1);
        businessContractNo = businessContractNo.substring(index + 1);
      }

      if (index > -1) {
        let name = businessContractNo;
        name += '-' + type;
        data.forEach((v, i) => {
          // 获取文件后缀名
          const suffix = v.url.substring(v.url.lastIndexOf('.') + 1);
          v.name = name + 0 + (i + 1) + '.' + suffix;
        });
        console.log(data);
        return data;
      } else {
        // 如果销售合同号不符合规则就直接返回
        return data;
      }
    },
    /**
     * 获取用-分隔的String的-内容
     * @param str 内容
     * @param cha 分隔符
     * @param num 第几个分隔符
     */
    findChart(str, cha) {
      const strIndexArr = [];
      for (let i = 0; i < str.length; i++) {
        if (cha === str[i]) {
          strIndexArr.push(i);
        }
      }
      const len = strIndexArr.length;
      if (len >= 2) {
        return strIndexArr[len - 2];
      } else {
        return -1;
      }
    },
    /**
     * 只允许输入数字
     */
    changeNumber(e, index, name) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      this.productList[index][name] = value;
    },
    /**
     * 千位分隔符
     */
    showFormate(e, index, name) {
      const value = e.target.value;
      if (value === '' || value === null) {
        return false;
      } else {
        this.productList[index][name] = this._thousandBitSeparator(value);
      }
    },
    /**
     * 检查小数位数
     * @param e 时间
     * @param name 属性名
     * @param len 最大整数位数
     * @param dot 截取位数
     * 范例：@blur="changeDecimal($event,'amount', 100000000, 8)"
     */
    changeDecimal(e, index, name, len, dot) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      if (name === 'unitPrice') {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      } else {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      }

      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= len) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, dot);
        }
      }
      // 如果入库重量 计算实际货值=商机里的品名的价格*入库重量
      if (name === 'instoreWeight' || name === 'unitPrice') {
        const unitPrice = this.productList[index].unitPrice; // 取输入单价
        const instoreWeight = this.productList[index].instoreWeight; // 取合计重量
        const actualAmount = this._formatFloat(unitPrice * instoreWeight, 2);
        this.productList[index].actualValue = actualAmount;
        this.productList[index].actualValueDisplay = actualAmount;
      }
      this.productList[index][name] = value;
    },
    // 产品信息删除按钮
    deleteProduct(row) {
      this.productList.splice(row, 1);
      if (this.productList.length > 1) {
        this.showSummary = true;
      } else {
        this.showSummary = false;
      }
    },
    // 产品信息添加按钮
    addProduct() {
      if (this.productList.length > 99) {
        return;
      }
      const productInfo = this.productList[0];
      const plantNo = this.formData.source === 1 ? productInfo.plantNo : null;
      const productObj = {
        joinName: null, // 货物信息
        actualWeight: null,
        category: null, // 大类
        contractWeight: null, // 合同重量
        contractWeightDisplay: null,
        country: null,
        instoreCount: null, // 入库件数
        instoreWeight: null, // 入库重量
        labelId: null, // 标签Id
        nameCn: null, // 中文品名
        nameEn: null, // 英文品名
        packageCount: null, // 件数
        plantNo: plantNo, // 厂号
        tradeCurrency: null,
        actualAmount: null, // 实际货值
        actualValueDisplay: null, // 实际货值显示用
        unitPrice: null, // 单价
        isshow: false,
        isShowInput: false,
      };
      this.productList.push(productObj); // 添加行
      if (this.productList.length > 1) {
        this.showSummary = true;
      } else {
        this.showSummary = false;
      }
    },

    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.validateProduct()) {
            // 计算入库件数
            const countTotal = this.productList.reduce((total, item) => {
              const expectCountTotal = Number(item.instoreCount);
              return total + expectCountTotal;
            }, 0);
            if (countTotal <= 0) {
              this.errorTip('入库件数合计必须大于0');
            } else {
              this.saveSubmitForm();
            }
          }
        }
      });
    },
    saveData() {
      const params = JSON.parse(JSON.stringify(this.formData));
      params.productDtos = Object.assign([], this.productList);
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/deliver/saveInStore',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功', 3000);
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    validateProduct() {
      // 判断控制条件
      let status = true;
      let flag = true;
      const paramdata = this.productList;
      const category = this.editData.category; // 期货现货类型
      const isAbnormal = this.formData.isAbnormal; // 是否异常入库
      for (let index = 0; index < paramdata.length; index++) {
        const item = paramdata[index];
        if (!item.nameCn) {
          // this.errorTip('请填写所有带星号的必填项');
          status = false;
          flag = false;
          break; // 有一列为空就不跳出不做判断了
        }
        if (item.instoreCount === '' || item.instoreCount === null) {
          // 入库件数
          // this.errorTip('请填写所有带星号的必填项');
          status = false;
          flag = false;
          break; // 有一列为空就不跳出不做判断了
        }
        if (category === 1) {
          // 期货判断 实际净重
          if (item.actualWeight === '' || item.actualWeight === null) {
            // this.errorTip('请填写所有带星号的必填项');
            status = false;
            flag = false;
            break; // 有一列为空就不跳出不做判断了
          }
        }
        if (category !== 1) {
          if (isAbnormal) {
            // 异常的判断
            if (item.unitPrice === '' || item.unitPrice === null) {
              // 单价判断
              // this.errorTip('请填写所有带星号的必填项');
              status = false;
              flag = false;
              break; // 有一列为空就不跳出不做判断了
            }
            if (item.instoreWeight === '' || item.instoreWeight === null) {
              // 入库重量判断
              // this.errorTip('请填写所有带星号的必填项');
              status = false;
              flag = false;
              break; // 有一列为空就不跳出不做判断了
            }
          } else {
            // 非异常判断
            if (item.contractWeight === '' || item.contractWeight === null) {
              // 重量判断
              // this.errorTip('请填写所有带星号的必填项');
              status = false;
              flag = false;
              break; // 有一列为空就不跳出不做判断了
            }
          }
        }
      }
      if (!flag) {
        this.errorTip('请填写所有带星号的必填项');
      }

      return status;
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.productDtos = Object.assign([], this.productList);
      if (!this.isLoading) {
        this.isLoading = true;
        this.ajax({
          type: 'POST',
          url: '/malicrm/sm/deliver/sureInStore',
          loading: true,
          data: {
            ...params,
          },
          success: (res) => {
            if (res.code === 200) {
              this.isLoading = false;
              this.successTip('保存成功');
              this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            } else {
              this.isLoading = false;
              this.errorTip(res.message);
            }
          },
        });
      }
    },
    btnIsAbnormal() {
      if (this.formData.isAbnormal) {
        this.formData.isAbnormal = false;
        this.formData.clickTitle = '切换为异常入库';
        this.$nextTick(() => {
          this.$refs.tableLayOut.doLayout();
        });
      } else {
        this.formData.isAbnormal = true;
        this.formData.clickTitle = '切换为正常入库';

        this.$nextTick(() => {
          this.$refs.tableLayOut.doLayout();
        });
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
  components: {
    SingleFileContent,
    productDialog,
    productPop2,
  },
};
</script>
<style lang="scss" scoped>
.table-goods-wrap {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
}
.table-goods-wrap:focus {
  outline: none;
}
.table-goods-info {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  color: #1a1a1a;
  border: 1px solid #dcdfe6;
  display: inline-block;
  flex: 1;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: top;
  box-sizing: border-box;
}
.table-goods-wrap .goods-tag {
  width: 38px;
  height: 28px;
  position: relative;
  margin-left: 1px;
  margin-right: -3px;
  margin-top: 2px;
  cursor: pointer;
}
.infor_doc_main {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.infor_doc_title {
  width: 80px;
  line-height: 30px;
}

.infor_doc_body {
  flex: 1;
}

.name-close {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 12px;
  color: #c0c4cc;
}

.card-upload__btns {
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;
  }
}
.erp-form__body.no-padding {
  padding-left: 0;
  padding-right: 0;
}
</style>
