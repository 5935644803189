<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">更新附件</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form" style="width: 100%">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="入库单号" prop="instoreReceiptNo">
                <el-input
                  :maxlength="30"
                  v-model="formData.instoreReceiptNo"
                  clearable
                  placeholder="请输入入库单号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="op-16">
          <el-col :span="24">
            <div class="confirmadd__title no-leftborder" style="margin-top: 20px; margin-bottom: 0">入库文件</div>
          </el-col>
          <el-col :span="24">
            <div class="border_stock_add" style="border: 1px solid rgba(220, 223, 230, 1); padding: 16px; margin: 17px 0">
              <SingleFileContent
                :fileType="1"
                :fromType="1"
                @success="getinBoundUrls"
                :max="3"
                :fileList="inBoundFileUrlsArray"
              ></SingleFileContent>
            </div>
          </el-col>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: {
    SingleFileContent,
  },
  props: {
    editData: {
      type: [Object],
    },
  },
  data() {
    return {
      coldlist: [],
      formData: {
        instoreReceiptNo: null,
        id: null,
        attachmentDtos: null,
        version: null,
      },
      inBoundFileUrlsArray: [], // 入库文件
      rules: {
        // 检验规则
      },
    };
  },
  created() {
    //
  },
  methods: {
    getinBoundUrls(data) {
      let attachmentList = [];
      data.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      // 修改文件名
      attachmentList = this.formatFileName(attachmentList);
      this.inBoundFileUrlsArray = attachmentList;
      this.formData.attachmentDtos = attachmentList;
    },
    formatFileName(fileArr) {
      const data = JSON.parse(JSON.stringify(fileArr));
      // 1.如果是旧的订单，则#销售合同号尾号(例如721-3)#-#证件类型#
      // 2.如果是新的订单，则#销售合同号客户编号(例如6003-0003)#-#合同编号#-#证件类型#
      // 销售合同号尾号 businessContractNo
      // 销售合同号客户编号 customerContractNo
      let { isNewOrder, businessContractNo, customerContractNo } = this.editData;
      const type = '入库单'; // 证件类型
      let index;
      if (isNewOrder) {
        // 新订单
        index = this.findChart(customerContractNo, '-', 1);
        businessContractNo = customerContractNo.substring(index + 1);
      } else {
        // 旧订单
        index = this.findChart(businessContractNo, '-', 1);
        businessContractNo = businessContractNo.substring(index + 1);
      }

      if (index > -1) {
        let name = businessContractNo;
        name += '-' + type;
        data.forEach((v, i) => {
          // 获取文件后缀名
          const suffix = v.url.substring(v.url.lastIndexOf('.') + 1);
          v.name = name + 0 + (i + 1) + '.' + suffix;
        });
        console.log(data);
        return data;
      } else {
        // 如果销售合同号不符合规则就直接返回
        return data;
      }
    },
    /**
     * 获取用-分隔的String的-内容
     * @param str 内容
     * @param cha 分隔符
     * @param num 第几个分隔符
     */
    findChart(str, cha) {
      const strIndexArr = [];
      for (let i = 0; i < str.length; i++) {
        if (cha === str[i]) {
          strIndexArr.push(i);
        }
      }
      const len = strIndexArr.length;
      if (len >= 2) {
        return strIndexArr[len - 2];
      } else {
        return -1;
      }
    },
    /**
     * 提交事件
     */
    submit() {
      console.log("🚀 ~ file: updateFile.vue ~ line 98 ~ submit ~ this.editData;", this.editData);
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/deliver/updateAttachment',
        data: {
          attachmentDtos: this.formData.attachmentDtos,
          id: this.editData.id,
          orderId: this.editData.orderId,
          instoreReceiptNo:this.formData.instoreReceiptNo,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
