<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">确认直送</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">确认直送</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form" style="width: 100%">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="送货日期" prop="deliverDate">
                <el-date-picker
                  v-model="formData.deliverDate"
                  type="date"
                  :disabled="true"
                  value-format="YYYY-MM-DD"
                  placeholder="-"
                  prefix-icon=" "
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="送货方式" prop="deliverModeName">
                <el-input :maxlength="8" v-model="formData.deliverModeName" clearable :disabled="true" placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报关单号" prop="declarationNo">
                <el-input :maxlength="6" v-model="formData.declarationNo" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报检号" prop="inspectionNo">
                <el-input :maxlength="8" v-model="formData.inspectionNo" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="送货地址" prop="deliverAddress">
                <el-input :maxlength="8" v-model="formData.deliverAddress" :disabled="true" clearable placeholder="-" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结算日期" prop="settleDate">
                <el-date-picker
                  v-model="formData.settleDate"
                  :disabled="true"
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="-"
                  prefix-icon=" "
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="送货问题" prop="remark">
                <el-input
                  v-model="deliverIssue"
                  placeholder=""
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      writtenOffList: [
        {
          value: 0,
          label: '直送',
        },
        {
          value: 1,
          label: '入库',
        },
      ],
      formData: {
        // 绑定表单数据
        autoCertNo: null,
        hsCode: null,
        id: null,
        isWrittenOff: 1,
        orderId: null,
        unitPrice: null,
        validDate: null,
        version: null,
        weight: null,
      },
      deliverIssue: '',
      rules: {},
    };
  },
  created() {
    this.getinitdata();
  },
  methods: {
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/deliverDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.deliverIssue = this.formData.deliverIssue;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    changeAutoCertNo(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符
      this.formData.autoCertNo = value;
    },
    /**
     * 检查小数位数
     * @param e 时间
     * @param name 属性名
     * @param len 最大整数位数
     * @param dot 截取位数
     */
    changeDecimal(e, name, len, dot) {
      let value = e.target.value;
      // 格式化汇率格式
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= len) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, dot);
        }
      }
      // 只能输入两个小数
      this.formData[name] = value;
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    saveSubmitForm() {
      // 保存或编辑表单
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/deliver/sureDeliver',
        data: {
          deliverIssue: this.deliverIssue,
          id: this.formData.id,
          orderId: this.editData.orderId,
          version: this.formData.version,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
